export const servicesList = [
  {
    name: 'Dashboard',
    key: 'dashboard_services',
  },
  {
    name: 'Quick Actions',
    key: 'quick_actions_services',
  },
  {
    name: 'Developers',
    key: 'developers_services',
  },
  {
    name: 'AML Screening',
    key: 'aml_screening_services',
  },
  {
    name: 'Document Analysis',
    key: 'document_analysis',
  },
  {
    name: 'API Report',
    key: 'api_report_services',
  },
  // {
  //   name: 'Fraud detection services',
  //   key: 'fraud_decision_services',
  // },
  {
    name: 'Address verification services',
    key: 'address_verification_services',
  },
  {
    name: 'Government Data',
    key: 'easy_lookup_services',
  },
  {
    name: 'Easy Onboard services',
    key: 'easy_onboard_services',
  },
  // {
  //   name: 'Verifications ',
  //   key: 'id_verification_services',
  // },
  {
    name: 'Api Logs ',
    key: 'api_logs_services',
  },
  {
    name: 'Profile',
    key: 'profile_services',
  },
  {
    name: 'Easy Detect  services',
    key: 'easy_detect_services',
  },
  {
    name: 'Easy Detect Report services',
    key: 'easy_detect_report_services',
  },
  {
    name: 'Audit Logs',
    key: 'audit_logs_services',
  },
  {
    name: 'Teams',
    key: 'teams_services',
  },
  {
    name: 'Billings',
    key: 'billings_services',
  },
  {
    name: 'Easy Credit check',
    key: 'credit_check_services',
  },
  {
    name: 'Reconciliation',
    key: 'reconciliation',
  },
  {
    name: 'Custom List',
    key: 'custom_link',
  },
  {
    name: 'Messaging logs ',
    key: 'messaging_report_services',
  },
  {
    name: 'BAAS',
    key: 'baas',
  },
  {
    name: 'Easy Authentication',
    key: 'easy_auth_services',
  },
];
